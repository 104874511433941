import { useEffect, useState } from 'react'
import './index.scss'


const Blog = () => {
    return (
        <>
            <h1 className='blog-header'>Hello Brother this is the blog (might come soon...?)</h1>
        </>
    )
}

export default Blog;